.nav-link {
    color: #fff !important;
}

#nav-search-icon, .input-group.md-form.form-sm.form-1 input{
    border: 1px solid #9e9e9e;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    background: #9e9e9e;
}

.nav-search input::placeholder {
	color: #fff !important;
}

.nav-search input {
	color: #222 !important;
}

.nav-search input:focus {
	border-bottom: none !important;
}

/* remove md-form margin(in-built) for this navbar-search */
.nav-search.md-form {
	margin: 0px;
}

.nav-dropdown-menu {
	border-radius: 0px;
	padding: 0px !important;
	width: 200px !important;
}

.nav-dropdown-item {
	text-align: center;
    cursor: pointer;
    color: #222 !important;
}

/*=== Language select ===*/

.nav-lang-dropdown {
	border-radius: 0px;
	padding: 0px !important;
	width: 80px !important;
	margin: 0px !important;
}

.nav-lang-icon-color {
	color: #fff !important;
}

.nav-lang-item {
	width: 100%;
}

/* language icon */
.lang-item-icon-div {
    position: relative;
    display: inline-block;
    margin-right: 12px;
}

.nav-lang-item:hover {
    cursor: pointer;
}

.lang-item-icon {
	background-color: #4285F4;
    padding: 6px;
    display: inline-block;
    border-radius: 14px;
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    min-width: 14px;
    line-height: 14px;
    font-weight: 500;
}

/* language label */
.lang-item-label {
	color: #666;
    font-weight: normal;
}