.footer-lang{
	color: #222;
	background-color: none;
	text-decoration: none !important;
}

/* === Begin: Used to keep footer at the bottom of the page === */
/* The other part is found in App.css */

footer {
	position: absolute;
	bottom: 0;
	width: 100%;
}

/* === End === */

