body {
  padding: 0px;
  margin:0px;
  font-size: 0.9em;
  font-family: 'Varela';
  background: #E0E0E0 !important;
}
.main{
	margin-left: 2.4%;
 	margin-right: 2.4%;
 	margin-top: 10px !important;
}
@media screen and (max-width: 768px){
	.main{
		margin-left: 2.4%;
	 	margin-right: 2.4%;
	 	margin-bottom: 170px;
	}
}
.nav-pic-sm{
	width: 35px;
	height: 35px;
	margin-left: 10px;
	margin-right: 10px;
}
.nav-pic-md{
	width:40px;
	height: 40px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 2px
}

.nav-pic-lg{
	width:75px;
	height:75px;
	object-fit: cover;
	margin-left: 10px;
	margin-right: 0px;
	margin-top: 8px
}

.item-pic-lg{
	width:100px;
	height:100px;
	object-fit: cover;
	margin-left: 10px;
	margin-right: 0px;
	margin-top: 8px
}

.site-background{
	background-color: white !important;
}
.site-second-color{
	color: #008081 !important;

}
.site-primary-color{
	color: #FF008A !important;
}

.site-primary-background{
	background: #FF008A !important;

}
.site-secondary-back{
	background: #008081 !important;
}

.site-black-color{
	color:black !important;
}
.nav-link{
	font-size: 14px !important;
	font-weight: bold;
	color: #008081;
}
.icon-react{
	font-size: 1.5em !important;
	font-weight: bold;
}
.background-pic{
	width: 100%;
	height:160px;
	margin:0px;
	border:5px solid #008081;
}
.background-pic-small{
	width: 100%;	
	height:80px;
	border:5px solid #FF008A;
}
.profile-picture{
	width: 150px;
    height: 150px;
    object-fit: cover;
    border:5px solid #008081 ;
    z-index: 1 !important;
}
.profile-picture-small{
	width: 100px;
    height: 100px;
    margin-top: -75px;
    border:5px solid #008081 ;
    z-index: 1 !important;
}
.lg-profile-picture {
	width: 250px;
    height: 250px;
    object-fit: cover;
    border:5px solid #008081 ;
    z-index: 1 !important;
	border-radius: 25px !important;
}
.boarder-secondary-line{
	border:1px solid #008081;
	margin-top: 2px;
	text-align: center;
}
.boarder-primary-line{
	border:1px solid #008081;
	margin-top: 2px;
	text-align: center;
}

.verifiedIcon{
	color: white;
	background:  #22c476  !important;
	text-align: center;

}
.dbverifiedIcon{
	color: white;
	background: #13a4fe !important;
	text-align: center;

}
.unverifiedIcon{
	color: white !important;
	background: #524f4b !important;
	text-align: center;

}
.user-rounded{
	border-radius: 10px !important;
}

.boarder-area{
	border:none;
	margin-top: 2px;
	text-align: center;
}

.starB{
	padding-top:1px !important;
	padding-bottom: 1px !important;
	padding-right: 5px !important;
	padding-left:5px !important;
	font-size: 0.7em !important;
	border-radius: 15px !important;
	margin-left: 7px !important;
}
.profile_name{
	color:#008081 !important;
	font-weight: bold;
	font-size: 1.0em;
	margin-top: 10px;
}
.space-below{
	margin-bottom: 20px !important;
}
.space-nav{
	margin-bottom: 70px !important;
}
.nav-above{
	margin-top: 70px !important;
}
.space-left{
	margin-left: 20px;
}

.space-above{
	margin-top:20px;
}
.space{
	margin: 10px
}

.space-music{
	margin-bottom: 40px;
	text-align: center;
}
.music-background{
	min-height: 100vh;
	width:96%;

}
.small-nav{
	height: auto;
	min-height: 100vh;
	position: relative;
	color:#008081 !important;
	
}

.btn{
	box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.0) !important;
	border-width: 1px !important;
}
.btn-outline-success:hover{
	border-color: #FF008A !important;
	color:#FF008A !important;
}

.btn-outline-success{
	border-color: #008081 !important;
	color:#008081!important;
}

.fc-center > h2{
	font-size: 1.0em !important;
	margin-top: 8px !important
}

.calender{
	height: 100px !important;
}
a{
	color: #008081 !important;
}

.react-datetime-picker--enabled{
	width: 50% !important;
	margin:5px;
}

.submission{
	margin-top: 70%;
	text-align: center;
	color: #FF008A;
	font-size: 1.6em;
	font-family: bold;
}

.submitted{
	text-align: center;
	color: #008081;
	font-size: 1.3em;
	font-family: bold;
}

.primary-color{
	background: #008081 !important;
	color: white;
	text-align: center !important;
	font-weight: bold !important;
	font-size: 1.6em;
}

/** This is to handle the design of the media  player*/
.player-container{
	max-height: 500px !important;
	padding: 10px !important;
	width: 100% !important;
    background:white !important;
    margin:0px !important;
}
.player-cover{
	min-height: 170px;
	max-height: 170px;
	height: 170px;
}
.artist-info{
	padding: 33px !important;
	text-align: center;
}
.artist-info> .artist-name{
	font-size: 1.1em !important;
	margin-top: -20px !important;
    font-weight: bold !important;
    color: #FF008A ;
}
.artist-info> .artist-song-name{
	font-size: 0.9em;
	color: #008081 !important;
}

.player-progress-container{
	width: 90% !important;
	margin-top:-15px !important;
    margin-bottom: -15px !important;
    background:black;
    height: 6px !important
}

.player-options{
	width: 90% !important;
}

.player-btn{
    color: white !important;
    background: #FF008A !important;
    /* font-size:  */
    
}

.player-controls{
	width: 50% !important
}
.player-controls> .big{	
	width: 40px !important;
	height: 40px !important;
}
.big>.fa-pause{
	font-size: 1.0em !important;
}

.player-controls>.medium{
	width: 35px !important;
	height: 34px !important;
}
.player-buttons>.small{
	width: 35px !important;
	height: 34px !important;
}
.medium>.fa{
	font-size: 1.0em !important;
}
.small>.fa{
	font-size: 1.0em !important;
}

.owner-info{
	font-size: 1.2em;
	color:#008081;
	font-weight: bold;
}

.margin-word{
	margin-left: 10px;
}
.music-margin{
	margin-left: 10px;

}

.tracklist{
	height: 480px;
}
.text-center{
	text-align: center !important
}
.cursor-pointer {
	cursor: pointer;
}

.dark-theme > .music-player-panel {
	/*background-color: rgba(255, 0, 138, 0.8) !important; */
	/*color:#FF008A;*/

}
.light-theme > .music-player-panel {
	/*background-color: rgba(0, 128, 129, 0.5) !important; */
	background-color: rgba(255, 0, 138, 0.2) !important;
}
.mainBackground{
	background: #E0E0E0 !important;
}
.activeItem{
	background:#E0E0E0 !important;

	color: black !important;
}

.active{
	color: #000000 !important;
}

.navActive {
	color:  #FF008A !important;
	/*background-color: #e4e6eb !important;*/
	border-bottom:4px solid #FF008A;
	/* border-radius: 10px; */
}

.user-nav-icon {
	padding-left: 8px !important;
	padding-right: 8px !important;
	margin-left: 5px !important;
	margin-right: 5px !important;
}

.user-nav-icon:hover {
	color:  #FF008A !important;
	background-color: #e4e6eb !important;
	/* border-bottom:4px solid #FF008A; */
}

.preloader{
	height: auto;
	width: 100%;
	z-index: 20;
    object-fit: cover;
    border-radius: 0px;
    position: absolute;
    text-align: center; 
}

.btn-primary{
	background: #FF008A !important;
	color:white !important;
}

.fullWith{
	width: 100% !important
}
.input-group {
	margin: 0px !important;
}

.activeNav{
	background: #FF008A !important;
	color:white !important;
}
.category-title{
	margin-bottom: 10px !important;
	margin-top: -5px;
}
.noBack > .card{
	margin: 5px !important;
	text-align: center;
}

.statsBadge{
	background:#E0E0E0;
	color:#008081;
	border-radius: 50px;
	font-size: 1.5em;
	font-weight: bold;
	text-align: center;
}

.specialBtn {
	padding-top: 20px;
	display: flex; 
	flex-direction: row;
	justify-content: space-between;
}

.specialIcons{
	font-size: 15px;
	color:#000000;
	background-color: white;
	border: 2px solid #000000;
	border-radius: 50%;
	padding: 15px;
	
}

.finImage {
	height:170px !important;
	width: auto;
}

.navDrop{
	font-weight: normal !important;
	color:#008081 !important;
}

.form-title{
	color: #008081 !important;
	text-align: center;
	font-size: 22px;
}
.notif-nav-link{
	color: #008081 !important;
	font-weight: bold !important;
	background-color: transparent !important;
	text-decoration: none !important;
}

.navBadge{
	text-align: right !important;
	margin-bottom: -15px !important;
	margin-left: 15px !important;
}
.noPadding{
	padding: 5px !important;
}
.noBadge{
	/* padding-top: 20px !important;  */
	font-size: 1.5em !important;
}

.text-black{
	color: black !important;
}
.music-text{
	color:#000000;
	font-weight: 400;
}
.text-grey{
	color: #666;
}
.borderNav{
	padding: 10px !important;
	padding-top: 5px !important;
	padding-bottom: 5px !important;
	background-color: #e4e6eb;
	border-radius: 50%;
	display: inline-block;
	text-align: center;
}

.iconWithMarginTop {
	margin-top: 4px !important;
}

.advert-img{
	width: 100%;
	height: auto;
}
/*
.StripeCheckout{
	color:green;
	background: white !important;
}
.StripeCheckout span{
	visibility: hidden !important;
}
*/

.invalid-data {
	margin-top: 2px;
	margin-left: 5px;
	color: #dc3545;
}

.fieldErrorMsg {
	box-sizing: border-box;
	color: rgb(40, 167, 69);
	display: block;
	font-family: "Bungee Inline", sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 19.2px;
	margin-top: -35px;
	overflow-wrap: break-word;
	text-align: left;
}

.fieldErrorMsg > .feedback-msg{
	width: 100%;
	margin-top: .25rem;
	font-size: 100%;
	color: #28a745;
}

.fieldErrorMsg > .valid {
	color: rgb(40, 167, 69);
}

.fieldErrorMsg > .invalid {
	color: #dc3545;
}


.fullInput{
  width: 600px !important
}

@media screen and (max-width: 500px){
  
  .fullInput{
    width: 300px !important
  }
}
@media screen and (min-width: 500px){
	.react-datetime-picker__inputGroup__input {
		width: 50px !important
}
}

.playlist-item{
    width: 100;
    height: 200px;
    position: relative;
    background-color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
}
.stripped{
	color: #666;
	margin-top: 10px;
	margin-bottom: 10px;
	border-style: 2em !important;
	border-color:red !important;

}
.boarder-line{
	border: 1px solid #666;;
	padding: 4px;
	text-align: center;
}
.border-track-line{
	border: 1px solid #666;;
	padding: 5px;
	margin-top: 10px;
}

.art-name{
	font-size: 0.8em;
	text-transform: capitalize;
	margin-top: -2px
	
}
.song-title{
	text-transform: uppercase;
	font-size: 0.9em;
	font-weight: bold

}

.event-title{
	text-transform: uppercase;
	font-size: 0.6em;
	font-weight: bold
}

.purchase-but{
	background: #008081 !important;
	color: white !important;
	height: auto;
	margin: 0px;
	font-size: 0.8em;
	text-align: center;
	width: 40%;
	padding: 2px
}
@media screen and (min-width: 500px){
	.right-nav{
		margin-top: -2%;
		margin-left: -40px;
		text-align: center;
		background-color: white;
		font-weight: bold;
	}
}

.SearchBox {
	background-color: #e4e6eb;
	border-radius: 50px;
	height: 40px;
	width: 300px;
	justify-content: space-between;
	display: flex;
	cursor: text;
}

.searchText{
	color: #6c757d;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 300;
}

.searchButton { 
	background: #cd006f;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-top: 9px;
    padding-left: 15px;
    padding-right: 15px;
	cursor: pointer;
 }

 @media screen and (max-width: 1200px){
	.SearchBox {
		width: auto;
	}
}

@media screen and (max-width: 768px){
	/* .SearchBox {
		background: white;
	} */

	.searchText{
		/* display: none; */
		padding-left: 10px;
		padding-right: 10px;
	}
	
	.searchButton { 
		/* background: white;
		color: #191919; */
		padding-left: 10px;
		padding-right: 10px;
	}
}

.label {
	padding-bottom:10px;
	text-align:left;
	
}
.form-text{
	font-size: 1.0em;
	font-weight: bold;
}
/*
.react-datetime-picker__inputGroup__year{
	width: 600px !important
}*/
.purchase-select{
	color:white;
	padding:5px;
	width:120px;
	background:#008081 !important;
	font-weight: bold;
	border-radius: 15px !important;
}

.hover-item:hover{
	background:rgba(0,0,0,.03) !important;
}

.profile-name{
	font-size:2.0em;
	font-weight: bolder;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.userNameText {
	font-weight: bold;
	color: whitesmoke;
}

.textUserType {
	color: wheat;
}

.text-statistics {
	display: inline;
	padding-left: 10px;
}

@media screen and (max-width : 1200px){
	.profile-name{
		font-size:1.5em;
	}
	
	.userNameText {
		font-size:0.8em;
	}
	
	.textUserType {
		font-size:1.0em;
	}

	.text-statistics {
		padding-left: 7px !important;
	}
}

@media screen and (max-width : 768px){
	.profile-name{
		font-size:1.1em;
	}
	
	.userNameText {
		font-size:0.7em;
	}
	
	.textUserType {
		font-size:0.8em;
	}

	.text-statistics {
		padding-left: 0px !important;
	}
}

.edge-circle{
	border-radius: 5px !important;
	padding: 5px;
	border: 1px solid #000000;
}

.profile-info{
	font-size:1.5em;
	margin-top:15px;
	margin-bottom: 15px;
}

.drop-btn{
	padding: 0px !important;
	padding-left: 5px !important;
	padding-right: 5px !important;
	background-color: transparent !important;
	color:white !important;
	border-width: 2px;
	border-color: white;

  }

/* .badge-circle{
	padding-right: 10px;
	padding-left: 10px;
	padding-top: 7px;
	padding-bottom: 7px;
	border-radius: 50%;
} */