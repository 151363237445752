/* ======= Begin Navbrand ========= */

.rotate-glyphicon {
/*        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);*/
        transform: scaleY(-1);
/*        filter: FlipH;
        -ms-filter: "FlipH";*/
}

/* ======= End Navbrand ========= */

/* ==== Begin Navbar Right ===== */

.user-nav-icon{
	margin-left: 15px;
	margin-right: 15px;

}

.user-nav-icon > .nav-link{
	color: #008081 !important;
	font-size: 0.8em !important;
}

.navActive > .nav-link{
	font-size: 0.8em !important;
}
/* ==== End Navbar Right ===== */

/* ======= Begin Sub Nav Options ========= */

.options{
	background: #FF008A;
}

.options-close {
	margin: 0px 10px; 
}

.options-close-label{
	font-size: 18px;
	font-weight: lighter;
	margin-left: 2px;
}

.options-items-group {
	margin: 10px 20px 0px;
}

.options-icon {
	font-size: 40px;
	color: #FF008A !important;
}

@media screen and (max-width: 768px){
	.options-close {
		margin: 0px; 
	}

	.options-items-group {
		margin: 0px;
	}

	.options-icon-size {
		font-size: 20px;
	}
}

/* ======= End Sub Nav Options ========= */
