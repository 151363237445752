.not-viewed {
	background-color: #E0E0E0 !important;
	border-bottom: 1px solid #999 !important;
}

.notifications {
	overflow: auto;
    overflow-x: hidden;
    min-height: 40vh;
    height: 60vh;
}

.notification {
	border-bottom: 1px solid #E0E0E0 !important;
	color: #008081 !important;
}

.notification:hover {
	background-color: #E0E0E0 !important;
}

.notif-position{
	position: relative !important;
	top: -40px !important;
	left: 30px;
}