
@fontface {
  font-family: 'Lato', cursive, sans-serif;
  src: url('https://fonts.googleapis.com/css?family=Bungee+Inline')
}

/* === Begin: Spinning icon before display === */

.loader:empty {
  background-image: url(logo.svg);
  background-repeat: no-repeat;
  background-position: center; 
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 2px solid rgba(0, 0, 0, 0.2);
/*  border-left: 2px solid #000000;*/
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* === End === */

/* === Begin: Used to keep footer at the bottom of the page === */
/* The other part is found in Footer.css */
* {
    box-sizing: border-box;
}

*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    position: relative;
}

.App {
    min-height: 100vh; /* will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: 100px; /* height of your footer */
    margin-left: 0px !important;
}

Button {
  padding: 5px !important;
  padding-right: 8px !important;
  padding-left: 8px !important;
  margin: 2px !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
}

Button > color > success {
  color:red !important;
}

Badge {
  border-radius: 10px !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
}
hr{
  padding:0px !important;
  /*margin: 1px !important */
}
.no-style{
  all:none !important;
}
/* === End === */
